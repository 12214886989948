import axios from "../axios/axiosConfig";

//endPoint
const endPointLogin = "/auth/admin/login";
const tokenVerify = "/auth/admin/verify-token";

// User-Login-API-start
export const adminAccountLogin = async (data) => {
  try {
    // console.log("Values are: ", data);
    const response = await axios.post(endPointLogin, data);
    return response;
  } catch (error) {
    console.log("ERROR On Account User Login API:", error);
    if (error.response) {
      return error.response;
    }
  }
};
// user-login-API-End

// Token-verify-API-start
export const verifyTokenAndFetchAdmin = async (data) => {
  try {
    // console.log("Values are: ", data);
    const response = await axios.post(tokenVerify, data);
    return response;
  } catch (error) {
    console.log("ERROR On Account User Login API:", error);
    if (error.response) {
      return error.response;
    }
  }
};
// Token-verify-API-End

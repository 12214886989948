import React, { useEffect, useState } from "react";
import Routes from "./routes/routes";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
//Services
import { verifyTokenAndFetchAdmin } from "./services/auth";
//redux
import { useSelector, useDispatch } from "react-redux";
import { updatedSignal } from "./slice/Login/updateSignal";
import { loginUserInfo } from "./slice/Login/loginUserSlice";
import { loginStatus } from "./slice/Login/loginSlice";

function App() {
  const dispatch = useDispatch();
  const signal = useSelector((state) => state.updateSignal.value);

  //fetching admin data through token
  const fetchAndVerifyAdmin = async (token) => {
    const params = {
      token: token,
    };
    const response = await verifyTokenAndFetchAdmin(params).then((data) => {
      return data;
    });
    console.log("Response from token verify Api: ", response.data);
    const adminData = response.data.data;
    if (response.data.status === "200") {
      const adminObj = {
        email: adminData.email,
        lastLoginDate: adminData.lastLoginDate,
        name: adminData.name,
        phone: adminData.phone,
        profileImage: adminData.profileImage,
        type: adminData.type,
        _id: adminData._id,
      };
      dispatch(loginUserInfo(adminObj));
    }
  };

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("TOKEN"));

    if (signal) {
      dispatch(updatedSignal(false));
      if (token) {
        console.log("Token available: ", token);
        fetchAndVerifyAdmin(token);
      } else {
        console.log("No token available");
      }
    }
  }, [signal]);
  return (
    <>
      <Routes />
    </>
  );
}

export default App;

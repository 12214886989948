import React, { Suspense, lazy } from "react";
// ----3rd-party---- //
import { Routes, Route, Navigate } from "react-router-dom";
// ----Component-for-scroll-to-top---- //
import ScrollToTop from "../components/ScrollToTop/ScrollToTop";
// ----Loader---- //
import { PulseLoader } from "react-spinners";
// ----pages---- //
const Dashboard = lazy(() => import("../pages/Dashboard"));
const Categories = lazy(() => import("../pages/Categories"));
const Login = lazy(() => import("../pages/Login"));
const FAQs = lazy(() => import("../pages/CMS"));
const Settings = lazy(() => import("../pages/Settings"));
const Analytics = lazy(() => import("../pages/Analytics"));
const Products = lazy(() => import("../pages/Products"));
const Sellers = lazy(() => import("../pages/Sellers"));
const Coupens = lazy(() => import("../pages/Coupens"));
const SellerDetails = lazy(() => import("../pages/SellerDetails"));
const Countries = lazy(() => import("../pages/Country"));
const CollectionPoints = lazy(() => import("../pages/CollectionPoints"));
const Orders = lazy(() => import("../pages/Orders"));
const OrderDetails = lazy(() => import("../pages/OrderDetails"));
const Transactions = lazy(() => import("../pages/Transactions"));

//Protected Route
import ProtectedRoute from "./ProtectedRoute";

//config
import config from "../config/config.json";

const url = config.routeUrl;

const spinnerStyle = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

export default function routes() {
  return (
    <>
      <Suspense
        fallback={
          <PulseLoader color={"#030870"} size={17} css={spinnerStyle} />
        }
      >
        <ScrollToTop />
        <Routes>
          <Route path={url} element={<Login />} />
          {/* <Route path={url + "dashboard"} element={<Dashboard />} />
          <Route path={url + "categories"} element={<Categories />} />
          <Route path={url + "faqs"} element={<FAQs />} />
          <Route path={url + "settings"} element={<Settings />} /> */}
          {/* <Route path={url + "analytics"} element={<Analytics />} /> */}
          {/* <Route path={url + "products"} element={<Products />} /> */}
          {/* <Route path={url + "sellers"} element={<Sellers />} /> */}
          {/* <Route path={url + "coupens"} element={<Coupens />} /> */}
          {/* <Route path={url + "seller-details"} element={<SellerDetails />} /> */}
          <Route
            path={url + "dashboard"}
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path={url + "categories"}
            element={
              <ProtectedRoute>
                <Categories />
              </ProtectedRoute>
            }
          />
          <Route
            path={url + "countries"}
            element={
              <ProtectedRoute>
                <Countries />
              </ProtectedRoute>
            }
          />
          <Route
            path={url + "collection-points"}
            element={
              <ProtectedRoute>
                <CollectionPoints />
              </ProtectedRoute>
            }
          />

          <Route
            path={url + "cms"}
            element={
              <ProtectedRoute>
                <FAQs />
              </ProtectedRoute>
            }
          />
          <Route
            path={url + "settings"}
            element={
              <ProtectedRoute>
                {" "}
                <Settings />
              </ProtectedRoute>
            }
          />
          <Route
            path={url + "analytics"}
            element={
              <ProtectedRoute>
                {" "}
                <Analytics />
              </ProtectedRoute>
            }
          />
          <Route
            path={url + "products"}
            element={
              <ProtectedRoute>
                {" "}
                <Products />
              </ProtectedRoute>
            }
          />
          <Route
            path={url + "orders"}
            element={
              <ProtectedRoute>
                {" "}
                <Orders />
              </ProtectedRoute>
            }
          />
          <Route
            path={url + "order-details"}
            element={
              <ProtectedRoute>
                {" "}
                <OrderDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path={url + "sellers"}
            element={
              <ProtectedRoute>
                {" "}
                <Sellers />
              </ProtectedRoute>
            }
          />
          <Route
            path={url + "coupens"}
            element={
              <ProtectedRoute>
                {" "}
                <Coupens />
              </ProtectedRoute>
            }
          />
          <Route
            path={url + "seller-details"}
            element={
              <ProtectedRoute>
                {" "}
                <SellerDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path={url + "transactions"}
            element={
              <ProtectedRoute>
                {" "}
                <Transactions />
              </ProtectedRoute>
            }
          />
          <Route path={url + "*"} element={<div>Page not Found</div>} />
        </Routes>
      </Suspense>
    </>
  );
}

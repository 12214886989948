import React from "react";
import { Navigate } from "react-router-dom";
import { getTokenStorage } from "../localStorage/index";
//config
import config from "../config/config.json";

const url = config.routeUrl;

function ProtectedRoute({ children }) {
  return getTokenStorage() ? children : <Navigate to={url} />;
}

export default ProtectedRoute;

import { createTheme } from "@mui/material";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

const breakpoints = createBreakpoints({});

const theme = createTheme({
  breakpoints,
  palette: {
    DarkBlue: "#030870",
    LightBlue: "#DCDEFE",
    BlueHover: "#02043e",
    RedHover: "#b40818",
    LightGray: "#CCCCCC",
    TextGray: "#757575",
    Purple: "#686ba9",
    Blue: "#7B7EB6",
    White: "#ffffff",
    Red: "#E50A1E",
    Text: "#757575",
    Black: "#000000",
    Green: "#33ae10",
  },
  typography: {
    htmlFontSize: 14,
    fontFamily: "Poppins",
    fontWeightMedium: 600,
    fontWeightRegular: 400,
    h1: {
      fontSize: "93px",
      lineHeight: "2",
      letterSpacing: "-1.5px",
    },
    h2: {
      fontSize: "58px",
      lineHeight: "2",
      letterSpacing: "-0.5px",
    },
    h3: {
      fontSize: "46px",
      lineHeight: "1.9",
      letterSpacing: "0px",
    },
    h4: {
      fontSize: "33px",
      lineHeight: "1.8",
      letterSpacing: "0.25px",
    },
    h5: {
      fontSize: "23px",
      lineHeight: "1.8",
      letterSpacing: "0px",
    },
    h6: {
      fontSize: "19px",
      lineHeight: "1.8",
      letterSpacing: "0.15px",
    },
    subtitle1: {
      fontSize: "15px",
      lineHeight: "1.8",
      letterSpacing: "0.15px",
    },
    subtitle2: {
      fontSize: "13px",
      lineHeight: "1.8",
      letterSpacing: "0.1px",
    },
    body1: {
      fontSize: "15px",
      lineHeight: "1.6",
      letterSpacing: "0.5px",
    },
    body2: {
      fontSize: "13px",
      lineHeight: "1.6",
      letterSpacing: "0.25px",
    },
    caption: {
      fontSize: "12px",
      lineHeight: "1.6",
      letterSpacing: "0.4px",
    },
    overline: {
      fontSize: "10px",
      lineHeight: "1.6",
      letterSpacing: "1.5px",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          textTransform: "capitalize",
          textDecoration: "none",
          fontWeight: "500",
          transition: ".2s linear all",
          ...(ownerState.variant === "outlined" && {
            border: `1px solid ${theme.palette[ownerState.colored]}`,
            color: theme.palette[ownerState.colored],
            background: "transparent",
            "&:hover": {
              border: `1px solid ${theme.palette[ownerState.colored]}`,
              backgroundColor: theme.palette[ownerState.colored],
              color: "#fff",
              boxShadow: "none",
            },
          }),
          ...(ownerState.variant === "fill" && {
            backgroundColor: theme.palette[ownerState.colored],
            color: "#fff",
            "&:hover": {
              backgroundColor: theme.palette[ownerState.hover],
            },
          }),
          ...(ownerState.sizing === "lg" && {
            height: "45px",
            fontSize: "13px",
          }),
          ...(ownerState.sizing === "md" && {
            height: "35px",
            fontSize: "12px",
          }),
          ...(ownerState.sizing === "sm" && {
            height: "28px",
            fontSize: "12px",
          }),
          ...(ownerState.medium && {
            fontWeight: "600",
          }),
        }),
      },
      defaultProps: {
        disableElevation: true,
      },
    },
  },
});

export default theme;

import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "../slice/Login/loginSlice";
import signalReducer from "../slice/signalSlice";
import updateSignalReducer from "../slice/Login/updateSignal";
import loginUserReducer from "../slice/Login/loginUserSlice";

export const store = configureStore({
  reducer: {
    loginStatus: loginReducer,
    signal: signalReducer,
    updateSignal: updateSignalReducer,
    loginUserInfo: loginUserReducer,
  },
});
